window.addEventListener("load", function () {

  $('.loading_frame').css('width', $('.album_section').width()+40);
  $('.loading_frame').css('height', $('.album_section').height())-20;

  var Masonry = require("masonry-layout");

  if ($(".grid").length > 0) {
    var msnry = new Masonry(".grid", {
      // options
      itemSelector: ".adm-grid-item",
      gutter: 10,
      horizontalOrder: false,
      fitWidth: true,
    });
  }

  $(".grid").animate({ opacity: 1 });
  $(".loading_frame").css("display", "none");

  // Disable right click on grid
  $("#masonry-grid").on("contextmenu", function (event) {
    event.preventDefault();
  });

  $("#full_size_image").on("contextmenu", function (event) {
    event.preventDefault();
  });

  function disable_ctrl_check() {
    var prevPicIndex = parseInt($("#full_size_image_index").val()) - 1;
    var nextPicIndex = parseInt($("#full_size_image_index").val()) + 1;
    var prevPic = $("img[data-position=" + prevPicIndex + "]");
    var nextPic = $("img[data-position=" + nextPicIndex + "]");
    if (parseInt(prevPic.length) == 0) {
      $("#previous").removeClass("masonry_ctrl");
      $("#previous").addClass("masonry_ctrl_disabled");
    } else {
      $("#previous").addClass("masonry_ctrl");
      $("#previous").removeClass("masonry_ctrl_disabled");
    }

    if (parseInt(nextPic.length) == 0) {
      $("#next").removeClass("masonry_ctrl");
      $("#next").addClass("masonry_ctrl_disabled");
    } else {
      $("#next").addClass("masonry_ctrl");
      $("#next").removeClass("masonry_ctrl_disabled");
    }
  }

  function display_full_size_image(image) {
    $("#full_size_image").attr("src", image.src);
    $("#full_size_image_index").val(image.dataset.position);

    if (image.dataset.title.length > 0) {
      $("#full_size_image_title").text(image.dataset.title);
    } else {
      $("#full_size_image_title").text("");
    }

    $("#full_size_image_background").css("display", "flex");

    disable_ctrl_check();
  }

  $(".adm-grid-item").on("click", function (event) {
    setTimeout(function () {
      display_full_size_image(event.target);
    }, 100);
  });

  $("#full_size_image_close").on("click", function (event) {
    $("#full_size_image_background").css("display", "none");
  });

  $("#next").on("click", function (event) {
    var nextPic = parseInt($("#full_size_image_index").val()) + 1;
    var newPic = $("img[data-position=" + nextPic + "]");

    if (newPic.length > 0) {
      display_full_size_image(newPic[0]);
    }
  });

  $("#previous").on("click", function (event) {
    var prevPic = parseInt($("#full_size_image_index").val()) - 1;
    var newPic = $("img[data-position=" + prevPic + "]");

    if (newPic.length > 0) {
      display_full_size_image(newPic[0]);
    }
  });

  $(document).on("keyup", function (event) {
    if (event.keyCode == 39) {
      //console.log("suivant");
      $("#next")[0].click();
    }
    if (event.keyCode == 37) {
      //console.log("précédent");
      $("#previous")[0].click();
    }
    if (event.keyCode == 27) {
      //console.log("escape");
      $("#full_size_image_close")[0].click();
    }
  });

  var touchstartX = 0;
  var touchendX = 0;

  function swipeAvailabilities() {
    if (touchendX < touchstartX - 100) {
      //console.log("swiped left!");
      $("#next")[0].click();
    }
    if (touchendX > touchstartX + 100) {
      //console.log("swiped right!");
      $("#previous")[0].click();
    }
  }

  $("#full_size_image_background").on("touchstart", (e) => {
    touchstartX = e.changedTouches[0].screenX;
  });

  $("#full_size_image_background").on("touchend", (e) => {
    touchendX = e.changedTouches[0].screenX;
    swipeAvailabilities();
  });
});
